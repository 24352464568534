import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
    margin: "0 auto",
    backgroundColor: "#eeeeee",
    width: "100%",
    height: "90vh",
    textAlign: "center",
    marginTop: "-20px"
  },

  content: {
    marginTop: 20
  },

  header: {
    fontSize: 50,
    fontWeight: 600,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: 40,
      fontWeight: 600
    }
  },

  sub: {
    fontSize: 20,
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.down("xs")]: {
      fontSize: 15
    }
  },

  button: {
    color: theme.palette.primary.contrastText,
    marginTop: 20
  }
}));

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography className={classes.header}> takemeonline </Typography>

        <Typography className={classes.sub}>
          Takemeonline makes it easy to take your store online in a matter of
          minutes.
        </Typography>

        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          component={Link}
          to="/register"
        >
          Create an account
        </Button>
      </div>
    </div>
  );
}

export default Home;
