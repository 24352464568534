import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2)
  },

  form: {
    fontSize: 20,
    display: "inline-block"
  },

  formInput: {
    width: 100,
    marginLeft: 10,
    [theme.breakpoints.down("xs")]: {
      width: 60
    }
  },

  inputs: {
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: 10
    }
  },

  button: {
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      marginTop: -4,
      fontSize: 10
    }
  },

  grid: {
    marginTop: 15
  }
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const handleChangeUsername = e => {
    setUsername(e.target.value);
  };

  const handleChangePassword = e => {
    setPassword(e.target.value);
  };

  const handleClick = e => {
    e.preventDefault();
    console.log(username);
    console.log(password);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Grid container justify="flex-end" className={classes.grid}>
            <form className={classes.form}>
              <TextField
                id="username"
                placeholder="Username"
                onChange={handleChangeUsername}
                className={classes.formInput}
                InputProps={{
                  classes: {
                    input: classes.inputs
                  }
                }}
              />
              <TextField
                id="password"
                placeholder="Password"
                type="password"
                onChange={handleChangePassword}
                className={classes.formInput}
                InputProps={{
                  classes: {
                    input: classes.inputs
                  }
                }}
              />
            </form>

            <Button
              onClick={handleClick}
              color="inherit"
              className={classes.button}
            >
              Login
            </Button>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
