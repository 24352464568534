import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
    margin: "0 auto",
    backgroundColor: "#3F84B5",
    width: "100%",
    height: "90vh",
    textAlign: "center",
    marginTop: "0px",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#eeeeee"
    }
  },

  header: {
    fontSize: 50,
    fontWeight: 600,
    marginBottom: 10,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: 40
    }
  },

  formWrapper: {
    position: "absolute",
    top: "15%",
    left: "15%",
    width: "70%",
    margin: "0 auto",
    padding: 20,
    padding: 50,
    borderRadius: 20,
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      width: "96%",
      padding: 0,
      padding: 0,
      borderRadius: 0,
      position: "absolute",
      top: "80px",
      left: "2%",
      backgroundColor: "#eeeeee"
    }
  },

  form: {
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  text: {
    marginBottom: 20
  },
  textLeft: {
    width: "48%",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  textRight: {
    width: "48%",
    marginLeft: "4%",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0%"
    }
  },

  error: {
    color: "red",
    padding: "20px"
  },

  success: {
    color: "green",
    padding: "20px"
  },

  button: {
    color: theme.palette.primary.contrastText
  }
}));

function Register() {
  const classes = useStyles();
  const [inputs, setInputs] = useState({});
  const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
  const lettersRegex = new RegExp(/^[a-zA-Z \s]*$/);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [nameErrorMsg, setNameErrorMsg] = useState("");
  const [success, setSuccess] = useState("");

  const validatePassword = () => {
    if (inputs.confirmpassword === inputs.password) {
      let password = inputs.password;
      if (strongPasswordRegex.test(password)) {
        return true;
      } else {
        setPasswordErrorMsg(" Password not strong enough.");
        return false;
      }
    } else {
      setPasswordErrorMsg(" Passwords do not match.");
      return false;
    }
  };

  const validateString = string => {
    if (lettersRegex.test(string)) {
      return true;
    } else {
      setNameErrorMsg(string + " is not a valid name");
      return false;
    }
  };

  const handleSubmit = event => {
    setPasswordErrorMsg("");
    setNameErrorMsg("");
    if (event) {
      event.preventDefault();
      let isPasswordValid = validatePassword();
      let isNameValid = validateString(inputs.name);
      if (isPasswordValid && isNameValid) {
        setSuccess("Registered");
      }
    }
  };

  const handleChange = event => {
    event.persist();
    setInputs(inputs => ({
      ...inputs,
      [event.target.id]: event.target.value
    }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.formWrapper}>
        <Typography className={classes.header}> takemeonline </Typography>

        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            id="email"
            variant="outlined"
            label=" Email"
            type="email"
            fullWidth
            required
            className={classes.text}
            onChange={handleChange}
          />

          <TextField
            id="password"
            variant="outlined"
            label=" Password"
            type="password"
            fullWidth
            required
            className={classes.textLeft}
            onChange={handleChange}
          />

          <TextField
            id="confirmpassword"
            variant="outlined"
            label="Confirm Password"
            type="password"
            fullWidth
            autoFocus
            required
            className={classes.textRight}
            onChange={handleChange}
          />

          <TextField
            id="name"
            variant="outlined"
            label="Name"
            type="text"
            pattern="[A-Za-z]"
            fullWidth
            autoFocus
            required
            className={classes.textLeft}
            onChange={handleChange}
          />

          <TextField
            id="phonenumber"
            variant="outlined"
            label=" Phone Number"
            type="number"
            fullWidth
            autoFocus
            required
            className={classes.textRight}
            onChange={handleChange}
          />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Create Account
          </Button>

          <div id="error" className={classes.error}>
            {passwordErrorMsg} {nameErrorMsg}
          </div>

          <div id="success" className={classes.success}>
            {success}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
