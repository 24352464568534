import AppBar from "./components/appbar";
import Main from "./components/main";
import Register from "./components/register";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#eeeeee"
  }
}));

function App() {
  const classes = useStyles();

  return (
    <Router>
      <div className="App" className={classes.root}>
        <AppBar />
      </div>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/main" component={Main} />
        <Route exact path="/register" component={Register} />
      </Switch>
    </Router>
  );
}

export default App;
